import { useEffect, useState } from "react";
import { getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import {
  createOrg,
  deleteOrgbyId,
  getOrgs,
  updateOrg,
} from "../endpoints/all/organisation";

const key = "user-admin-values";
const useOrg = () => {
  const keyValues = getLocalStorageWithExpiration(key);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState({
    isCreatingRole: false,
    isFetchingRoles: false,
  });

  const getRolesList = async () => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingRoles: true }));

    try {
      const { data, status } = await getOrgs(token);
      if (data.status || status === 200) {
        const roles = data?.data?.allOrganisationRole?.map((i) => {
          return { label: i.name, value: i.id };
        });

        setRoleList(roles);
        setLoading((prev) => ({ ...prev, isFetchingRoles: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingRoles: false }));
    }
  };

  const createRoleFn = async ({ name }) => {
    const { token } = keyValues;
    const field = {
      name,
    };
    setLoading((prev) => ({ ...prev, isCreatingRole: true }));

    try {
      const { data, status } = await createOrg(token, field);
      if (status === 201 || data.status) {
        toast.success(data?.message);
        getRolesList();
        setLoading((prev) => ({ ...prev, isCreatingRole: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isCreatingRole: false }));
      toast.error("Failed to Create Organisation Role");
    }
  };

  const updateOrgFn = async ({ name, id }) => {
    const { token } = keyValues;
    const field = {
      name,
      id,
      _method: "PUT",
    };
    setLoading((prev) => ({ ...prev, isFetchingRoles: true }));

    try {
      const { data, status } = await updateOrg(token, field);
      if (status === 202 || data.status) {
        toast.success(data?.message);
        getRolesList();
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingRoles: false }));
      toast.error("Failed to Update Role");
    }
  };

  const deleteRole = async ({ id }) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteOrgbyId(token, id);
      if (status === 202 || data.status === true) {
        toast.success(data?.message);
        getRolesList();
      }
    } catch (error) {
      toast.error("Failed to delete Role");
    }
  };

  useEffect(() => {
    getRolesList();
  }, [keyValues?.token]);

  return {
    loading,
    roleList,
    createRoleFn,
    deleteRole,
    updateOrgFn,
  };
};

export default useOrg;
