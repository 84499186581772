import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import celebrate from "../../assets/celebrate.png";
import "./ApplicantModalContent.css";

// export function ApprovalModalContent({ setOpenApprovalModal }) {
//     return (
//         <div className='approval-modal-content'>
//             <AiOutlineCloseCircle className='close-btn' onClick={() => { setOpenApprovalModal(false) }} />
//             <div className="img-box">
//                 <img src={celebrate} alt="congrats" />
//             </div>
//             <h3>Success</h3>
//             <p>You've been succesfully verified!</p>
//         </div>
//     )
// }

export function ModalContent({
  setOpenModal,
  value,
  onChange,
  onSubmit,
  title
}) {
  return (
    <div className="rejection-modal-content">
      <div className="rejection-text">
        <h3>{title}</h3>
        <AiOutlineCloseCircle
          className="close-btn"
          onClick={() => {
            setOpenModal(false);
          }}
        />
      </div>
      <div className="msg-box">
        <p>Message</p>
        <textarea
          placeholder="Type a message"
          value={value}
          onChange={onChange}
          cols="30"
          rows="10"
        />
        <button onClick={onSubmit} className="btn send">
          Send
        </button>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
          className="btn cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

// export function DocumentModalContent({ setOpenDocumentModal, imgSrc }) {
//   return (
//     <div className="document-modal-content">
//       <div className="title-box">
//         <p>Curiculum Vitae</p>
//         <AiOutlineCloseCircle
//           className="close-btn"
//           onClick={() => {
//             setOpenDocumentModal(false);
//           }}
//         />
//       </div>
//       <div className="cv-box">
//         <img src={imgSrc} alt="doc" />
//       </div>
//       <div className="approve-btn-box">
//         <button className="reject">Reject</button>
//         <button className="shortlist">Approve</button>
//       </div>
//     </div>
//   );
// }
