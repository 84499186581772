import { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration, isEmpty } from "../utils";
import { toast } from "react-toastify";
import { getSingleUserDetails } from "../endpoints/singleUser";
import { useTypedSelector } from "./useTypedSelector";

const key = "user-admin-values";
const useSingleUserDetails = ({ id, acc_type, navigate }) => {
  const adminUser = useTypedSelector((store) => store.user);
  const keyValues = getLocalStorageWithExpiration(key);
  const [userDetail, setUserDetail] = useState(null);

  const getSingleUserDetailsFn = async () => {
    const { token } = keyValues;
    try {
      const { data, status } = await getSingleUserDetails(token, id, acc_type);
      if (status === 200 && data?.status) {
        setUserDetail(data?.data);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get user detail", errorProps);
      }
      navigate(-1)
      setUserDetail(null);
    }
  };

  useEffect(() => {
    if (!isEmpty(adminUser) && keyValues) {
      getSingleUserDetailsFn();
    }
  }, [!isEmpty(adminUser) ]);

  return { userDetail, getSingleUserDetailsFn };
};

export default useSingleUserDetails;
