import { config } from "../../config";
import axios from "axios";

export const getOrgs = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-organisation-role`
  );
  return { data: res.data, status: res.status };
};

export const createOrg = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-organisation-role`,
    field
  );
  return { data: res.data, status: res.status };
};

export const updateOrg = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-organisation-role/${field.id}`,
    field
  );
  return { data: res.data, status: res.status };
};

export const deleteOrgbyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-organisation-role/${id}`
  );
  return { data: res.data, status: res.status };
};
