import React from "react";
import "./ApplicantsPage.css";
import ApplicantsContainer from "../../../components/applicantsContainer/ApplicantsContainer";
import { SingleApplicant } from "../../../components/singleApplicant/SingleApplicant";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const ApplicantsPage = ({
  children,
  userData,
  title,
  loading,
  NoDataMsg,
  currentItems,
  handleNextPage,
  handlePrevPage,
  totalPages,
  currentPage,
  search,
  handleChange,
}) => {
  const type_acc =
    title === "Individual"
      ? "individual"
      : title === "Organisation"
      ? "organisation"
      : undefined;
  return (
    <div className="applicants-page-container">
      <div className="applicants-page-right">
        <div className="applicants-page-results">
          {children}
          <ApplicantsContainer
            applicantsTitle={title}
            show={!loading}
            search={search}
            handleChange={handleChange}
          />

          <>
            <div className="applicants-box-result">
              <h5>Full Name</h5>
              <h5>Email</h5>
              <h5>Date Created</h5>
              <h5>Status</h5>
              <span className="dot-bg"></span>
            </div>

            {loading ? (
              <div className="loading-state">
                <Bars
                  height="50"
                  width="60"
                  color="#f5f7fa"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : userData && userData?.length > 0 ? (
              userData?.map((user, index) => {
                return (
                  <Link
                    to={`/backend/${user.id}/${user.account_type || type_acc}`}
                    key={index}
                  >
                    <SingleApplicant
                      id={user.id}
                      key={user.email}
                      name={user.name}
                      email={user.email}
                      status={user.status}
                      date={user.created_at}
                    />
                  </Link>
                );
              })
            ) : (
              <div className="loading-state">
                <p>{NoDataMsg}</p>
              </div>
            )}
          </>

          {!loading && currentItems.length > 0 && (
            <div className="pagination">
              <button onClick={() => handlePrevPage()}>{"<"}</button>
              <p>{currentPage}</p>
              <p> / </p>
              <p>{totalPages}</p>
              <button onClick={() => handleNextPage()}>{">"}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantsPage;
