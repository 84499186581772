import { config } from "../../config";
import axios from "axios";

export const getSingleUserDetails = async (token, id, acc_type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-${acc_type}/${id}`
  );
  return { data: res.data, status: res.status };
};

export const updateUserStatus = async (token, id, acc_type, fields) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-${acc_type}-status/${id}`,
    fields
  );
  return { data: res.data, status: res.status };
};