import React, { memo, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import "./CustomButton.css";

const CustomButton = ({
  type,
  title,
  loading,
  customStyles,
  onClick,
  children,
  disable,
  className,
  background,
  border,
  variant,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={`btn-custom ${className}`}
      type={type}
      disabled={loading || disable}
      style={{
        ...customStyles,
        backgroundColor:
          variant === "outline"
            ? "transparent"
            : disable
            ? "#5A5A5A"
            : background
            ? background
            : "#245293",
        border: `2px solid ${disable ? "#5A5A5A" : border ? border : "#245293"}`,
        opacity: isHovered ? 0.6 : 1,
        color:
          (variant === "outline") & !disable
            ? "#245293"
            : (variant === "outline") & disable
            ? "#5A5A5A"
            : "#fff",
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {loading ? (
        <TailSpin
          visible={true}
          height="25"
          width="25"
          color="#fff"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        title || children
      )}
    </button>
  );
};

export default memo(CustomButton);
