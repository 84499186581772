import React, { useState } from "react";
import "./SingleApplicantPage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardModal from "../../../components/dashboardModal/DashboardModal";
import { ModalContent } from "../../../components/applicantModalContent/ApplicantModalContent";
import useSingleUserDetails from "../../../hooks/useSingleUserDetails";
import Skeleton from "@mui/material/Skeleton";
import { config } from "../../../config";
import useStatusUpdate from "../../../hooks/useStatusUpdate";
import fileIcon from "../../../assets/file-pdf-colored.png";
import { toast } from "react-toastify";
import { errorProps } from "../../../utils";

const SingleApplicantPage = () => {
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [openBlockedModal, setOpenBlockedModal] = useState(false);
  const [rejMsg, setRejMsg] = useState("");
  const [bloMsg, setBloMsg] = useState("");
  const navigate = useNavigate();
  const { updateUserStatusPost } = useStatusUpdate({
    navigate,
  });

  const params = useParams();
  const { userDetail } = useSingleUserDetails({
    id: params.id,
    acc_type: params.acc_type,
    navigate,
  });

  const handleReject = () => {
    if (rejMsg.length === 0) {
      toast.warning("please add a rejection message", errorProps);
    } else {
      updateUserStatusPost({
        id: userDetail?.id,
        acc_type: userDetail?.account_type,
        status: "3",
        message: rejMsg,
      });
    }
  };

  const handleBlocked = () => {
    if (bloMsg.length === 0) {
      toast.warning("please add a blocked message", errorProps);
    } else {
      updateUserStatusPost({
        id: userDetail?.id,
        acc_type: userDetail?.account_type,
        status: "4",
        message: rejMsg,
      });
    }
  };

  const handleApprove = () => {
    updateUserStatusPost({
      id: userDetail?.id,
      acc_type: userDetail?.account_type,
      status: "2",
      message: "You were successfully accepted.",
    });
  };

  const cvFile = `${config.getPublicImages}/${userDetail?.cv}`;

  const licenseFile = `${config.getPublicImages}/${userDetail?.license}`;

  const profilePix = `${config.getPublicImages}/${userDetail?.profile_pix}`;

  return (
    <>
      <div className="singleApplicantPageContainer">
        <div className="single-applicant-page-content">
          <div className="page-content-top">
            {userDetail === null ? (
              <Skeleton variant="circular" width={100} height={100} />
            ) : userDetail?.profile_pix === "" ? (
              <div className="top-image">
                <img
                  src={
                    "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
                  }
                  alt="profile"
                />
              </div>
            ) : (
              <div className="top-image">
                <img src={profilePix} alt="profile" />
              </div>
            )}
            <div className="top-name">
              {userDetail === null ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={100}
                />
              ) : (
                <h3>
                  {userDetail?.name.charAt(0).toUpperCase() +
                    userDetail?.name.slice(1)}
                </h3>
              )}
              {userDetail === null ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
              ) : (
                <span
                  className={
                    userDetail?.status === "2"
                      ? "status verified"
                      : userDetail?.status === "1"
                      ? "status pending"
                      : userDetail?.status === "3"
                      ? "status rej"
                      : userDetail?.status === "4"
                      ? "status blo"
                      : null
                  }
                >
                  {userDetail?.status === "1"
                    ? "pending"
                    : userDetail?.status === "2"
                    ? "verified"
                    : userDetail?.status === "3"
                    ? "rejected"
                    : userDetail?.status === "4"
                    ? "blocked"
                    : ""}
                </span>
              )}
            </div>

            {userDetail === null ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={500} />
            ) : (
              <div className="top-email">
                <p>{userDetail?.email}</p>
                <div className="dot">
                  <p>.</p>
                </div>
                <p>{userDetail?.phone}</p>
                {userDetail?.division !== null && (
                  <div className="dot">
                    <p>.</p>
                  </div>
                )}
                {userDetail?.division !== null && (
                  <p>{userDetail?.division?.name}</p>
                )}
                {userDetail?.address !== "" && (
                  <div className="dot">
                    <p>.</p>
                  </div>
                )}
                {userDetail?.address !== "" && <p>{userDetail?.address}</p>}
              </div>
            )}

            <div className="top-buttons">
              <button
                disabled={userDetail === null || userDetail?.status === "4"}
                className={`btn ${
                  userDetail === null || userDetail?.status === "4"
                    ? "disable-btn"
                    : "block"
                }`}
                onClick={() => {
                  setOpenBlockedModal(true);
                }}
              >
                Block
              </button>
              <button
                disabled={userDetail === null || userDetail?.status === "3"}
                className={`btn ${
                  userDetail === null || userDetail?.status === "3"
                    ? "disable-btn"
                    : "reject"
                }`}
                onClick={() => {
                  setOpenRejectionModal(true);
                }}
              >
                Reject
              </button>
              <button
                disabled={userDetail === null || userDetail?.status === "2"}
                className={`btn ${
                  userDetail === null || userDetail?.status === "2"
                    ? "disable-btn"
                    : "approve"
                }`}
                onClick={handleApprove}
              >
                Approve
              </button>
            </div>
          </div>
          <div className="page-content-bottom">
            <div className="bottom-head">
              <p className="active-tab">Credentials</p>
              <p>Revenue</p>
            </div>
            <div className="content">
              <div className="box wider">
                <div className="upper">
                  <div className="upper-inner space">
                    <h6>Location</h6>

                    {userDetail === null ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={100}
                      />
                    ) : (
                      <p>{userDetail?.state + ", " + userDetail?.country}</p>
                    )}
                  </div>
                  <div className="upper-inner space">
                    <h6>Phone number</h6>
                    {userDetail === null ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={100}
                      />
                    ) : (
                      <p>+{userDetail?.phone}</p>
                    )}
                  </div>

                  <div className="space">
                    <h6>Date of birth</h6>
                    {userDetail === null ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={100}
                      />
                    ) : (
                      <p>{userDetail?.date_of_birth}</p>
                    )}
                  </div>
                </div>
                <div className="middle">
                  <div className="upper-inner space-2">
                    <h6>Email</h6>
                    {userDetail === null ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={100}
                      />
                    ) : (
                      <p>{userDetail?.email}</p>
                    )}
                  </div>
                  <div className="space-2">
                    <h6>Skills</h6>
                    <div className="skills-list">
                      {userDetail === null ? (
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                          width={100}
                        />
                      ) : (
                        userDetail?.skills.length > 0 &&
                        userDetail?.skills.map((skill) => {
                          return <p key={skill}>{skill + ","} </p>;
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="box smaller">
                <h6>Attachement</h6>
                {userDetail === null ? (
                  <div className="inner-box">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem" }}
                      width={100}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem" }}
                      width={100}
                    />
                  </div>
                ) : (
                  <div className="inner-box">
                    {userDetail?.cv?.length > 0 ||
                    userDetail?.license?.length > 0 ? (
                      <>
                        {userDetail?.cv && userDetail?.cv?.length > 0 && (
                          <Link className="link-inner" to={cvFile}>
                            <img src={fileIcon} alt="file" />
                            <p>my-cv.pdf</p>
                          </Link>
                        )}

                        {userDetail?.license &&
                          userDetail?.license?.length > 0 && (
                            <Link className="link-inner" to={licenseFile}>
                              <img src={fileIcon} alt="file" />
                              <p>licence</p>
                            </Link>
                          )}
                      </>
                    ) : (
                      <p>No Attachement Found</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DashboardModal isOpen={openRejectionModal}>
        <ModalContent
          title="Rejection Message"
          setOpenModal={setOpenRejectionModal}
          value={rejMsg}
          onChange={(event) => {
            setRejMsg(event.target.value);
          }}
          onSubmit={handleReject}
        />
      </DashboardModal>

      <DashboardModal isOpen={openBlockedModal}>
        <ModalContent
          title="Blocked Message"
          setOpenModal={setOpenBlockedModal}
          value={bloMsg}
          onChange={(event) => {
            setBloMsg(event.target.value);
          }}
          onSubmit={handleBlocked}
        />
      </DashboardModal>

      {/* <DashboardModal isOpen={openDocumentModal}>
        <DocumentModalContent
          setOpenDocumentModal={setOpenDocumentModal}
          imgSrc={cv}
        />
      </DashboardModal> */}
    </>
  );
};

export default SingleApplicantPage;
