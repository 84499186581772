import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/CustomInput";
import CustomButton from "../../../../components/customButton/CustomButton";
import { toast } from "react-toastify";
import useOrg from "../../../../hooks/useOrg";
import { GrFormEdit } from "react-icons/gr";

const OrganisationRole = () => {
  const [values, setValues] = useState({
    role: "",
    id: null,
  });

  const { loading, roleList, createRoleFn, deleteRole, updateOrgFn } = useOrg();

  return (
    <div className="location-settings">
      <div className="loc-top">
        <div className="loc-input-bg">
          <div className="loc-input">
            <CustomInput
              id="org"
              label="Organisation"
              placeholder="Enter Organisation Name"
              type="text"
              value={values.role}
              onChange={(e) => setValues((prev) => ({ ...prev, role: e }))}
              inputStyles={{
                height: "40px",
                borderRadius: "3px",
              }}
              labelStyle={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            />
          </div>

          <div className="loc-btn">
            <CustomButton
              onClick={() => {
                if (values.role.length > 0 && values.id === null) {
                  createRoleFn({ name: values.role });
                  setValues({
                    role: "",
                    id: null,
                  });
                } else if (values.role.length > 0 && values.id !== null) {
                  updateOrgFn({ name: values.role, id: values.id });
                  setValues({
                    role: "",
                    id: null,
                  });
                } else {
                  toast.warning("Add Organisation Role");
                }
              }}
              title={values.id === null ? "Submit" : "Update"}
              type="btn"
              loading={loading.isCreatingRole}
              customStyles={{
                width: "100px",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
                cursor: "pointer",
                height: "40px",
              }}
            />
          </div>
        </div>
      </div>

      <div className="loc-content">
        <h5>List of Organisation Created</h5>

        <div className="loc-states">
          {roleList?.length === 0 && loading.isFetchingRoles ? (
            <div className="loc-empty">
              <p>loading...</p>
            </div>
          ) : roleList?.length === 0 && !loading.isFetchingRoles ? (
            <div className="loc-empty">No division added, Add divisions</div>
          ) : (
            roleList?.map((i) => (
              <div key={i.value} className="loc-states-each">
                <span
                  onClick={() => {
                    setValues({ role: i.label, id: i.value });
                  }}
                >
                  <GrFormEdit />
                </span>
                <div className="main">{i.label}</div>
                <span onClick={() => deleteRole({ id: i.value })}>x</span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganisationRole;
