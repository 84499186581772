import React, { useState } from "react";
import CustomInput from "../../../../components/customInput/CustomInput";
import CustomButton from "../../../../components/customButton/CustomButton";
import { toast } from "react-toastify";
import useDivision from "../../../../hooks/useDivision";
import { GrFormEdit } from "react-icons/gr";

const Division = () => {
  const [values, setValues] = useState({
    division: "",
    id: null,
  });

  const {
    loading,
    divisionList,
    createDivisionFn,
    deleteDivision,
    updateDivisionFn,
  } = useDivision();

  return (
    <div className="location-settings">
      <div className="loc-top">
        <div className="loc-input-bg">
          <div className="loc-input">
            <CustomInput
              id="division"
              label="Division"
              placeholder="Enter Division Name"
              type="text"
              value={values.division}
              onChange={(e) => setValues((prev) => ({ ...prev, division: e }))}
              inputStyles={{
                height: "40px",
                borderRadius: "3px",
              }}
              labelStyle={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            />
          </div>

          <div className="loc-btn">
            <CustomButton
              onClick={() => {
                if (values.division.length > 0 && values.id === null) {
                  createDivisionFn({ name: values.division });
                  setValues({
                    division: "",
                    id: null,
                  });
                } else if (values.division.length > 0 && values.id !== null) {
                  updateDivisionFn({ name: values.division, id: values.id });
                  setValues({
                    division: "",
                    id: null,
                  });
                } else {
                  toast.warning("Add Division");
                }
              }}
              title={values.id === null ? "Submit" : "Update"}
              type="btn"
              loading={loading.isCreatingDivision}
              customStyles={{
                width: "100px",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
                cursor: "pointer",
                height: "40px",
              }}
            />
          </div>
        </div>
      </div>

      <div className="loc-content">
        <h5>List of Division Created</h5>

        <div className="loc-states">
          {divisionList?.length === 0 && loading.isFetchingDivisions ? (
            <div className="loc-empty">
              <p>loading...</p>
            </div>
          ) : divisionList?.length === 0 && !loading.isFetchingDivisions ? (
            <div className="loc-empty">No division added, Add divisions</div>
          ) : (
            divisionList?.map((i) => (
              <div key={i.value} className="loc-states-each">
                <span
                  onClick={() => {
                    setValues({ division: i.label, id: i.value });
                  }}
                >
                  <GrFormEdit />
                </span>
                <div className="main">{i.label}</div>
                <span onClick={() => deleteDivision({ id: i.value })}>x</span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Division;
