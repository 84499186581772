import React, { useEffect, useState } from "react";
import "./Login.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc1.png";
import { Link } from "react-router-dom";
import Logo2 from "../../../assets/Group.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useLogin from "../../../hooks/useLogin";
import CustomInput from "../../../components/customInput/CustomInput";
import { useNavigate } from "react-router-dom";
import { getLocalStorageWithExpiration } from "../../../utils";
import CustomButton from "../../../components/customButton/CustomButton";

const Login = () => {
  const [passwordEye, setPasswordEye] = useState(false);

  const { values, handleChange, errors, touched, handleSubmit, handleBlur, isValid, loading } =
    useLogin();

  const navigate = useNavigate();

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const key = "user-admin-values";
  const access_token = getLocalStorageWithExpiration(key);

  useEffect(() => {
    if (access_token) {
      navigate("/backend");
    }
  }, []);

  const styles = {
    eye: {
      position: "absolute",
      top: "9.5px",
      right: "10px",
      fontSize: "22px",
      cursor: "pointer",
    },
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-left-content">
          <Link to="/login">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>

          <div className="login-left-text">
            <h3>
              Embrace flexible and rewarding practice through healthcare locum
            </h3>
            <p>
              Join the largest community of healthcare professionals on locum
              staffing in Africa as a Healthcare professional; and kick-start a
              flexible and rewarding practice.
            </p>
          </div>
          <div className="login-left-image">
            <img src={Doctor} alt="" />
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="login-right-body">
          <div className="login-button-box">
            <Link to="/login">
              <img src={Logo2} alt="logo" />
            </Link>
          </div>

          <div className="login-right-content">
            <form onSubmit={formSubmit}>
              <div className="form-label">
                <CustomInput
                  id="email"
                  label="Email address"
                  placeholder="Enter email address"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  touched={touched.email}
                  error={errors.email}
                  onBlur={(e) => handleBlur(e)}
                />
                <CustomInput
                  id="password"
                  label="Password"
                  placeholder="Password"
                  type={passwordEye ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  touched={touched.password}
                  error={errors.password}
                  onBlur={(e) => handleBlur(e)}
                  endIcon={
                    passwordEye ? (
                      <AiOutlineEye
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    )
                  }
                />
              </div>
              <CustomButton disable={!isValid} type="submit" title="Login" loading={loading} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
