import { useEffect, useState } from "react";
import { getLocalStorageWithExpiration, isEmpty } from "../utils";
import { getUsersBy, getUsersCountBy } from "../endpoints/all";
import { useTypedSelector } from "./useTypedSelector";

const key = "user-admin-values";
const useApplicantsOrganisation = () => {
  const adminUser = useTypedSelector((store) => store.user);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [usersCount, setUsersCount] = useState({
    allOrg: 0,
    newOrg: 0,
    accOrg: 0,
    rejOrg: 0,
    bloOrg: 0,
  });
  const keyValues = getLocalStorageWithExpiration(key);

  const getAllOrgUsers = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "organisation";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allOrganisation);
      }
    } catch (error) {
      setLoading(false);
      setUsers([]);
    }
  };

  const getNewOrgUsers = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "organisation";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allNewOrganisation);
      }
    } catch (error) {
      setLoading(false);
      setUsers([]);
    }
  };

  const getAcceptedOrgUsers = async () => {
    const { token } = keyValues;
    const path_type = "accepted";
    const acc_type = "organisation";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allAcceptedOrganisation);
      }
    } catch (error) {
      setLoading(false);
      setUsers([]);
    }
  };

  const getRejectedOrgUsers = async () => {
    const { token } = keyValues;
    const path_type = "rejected";
    const acc_type = "organisation";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allRejectionOrganisation);
      }
    } catch (error) {
      setLoading(false);
      setUsers([]);
    }
  };

  const getBlockedOrgUsers = async () => {
    const { token } = keyValues;
    const path_type = "blocked";
    const acc_type = "organisation";
    setLoading((prev) => ({
      ...prev,
      orgUsersLoading: true,
    }));
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allBlockedOrganisation);
      }
    } catch (error) {
      setLoading(false);
      setUsers([]);
    }
  };

  const getAllOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          allOrg: data?.allOrganisationCount,
        }));
      }
    } catch (error) {
      setUsersCount((prev) => ({ ...prev, allOrg: 0 }));
    }
  };

  const getNewOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          newOrg: data?.allNewOrganisationCount,
        }));
      }
    } catch (error) {
      setUsersCount((prev) => ({ ...prev, newOrg: 0 }));
    }
  };

  const getAcceptedOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "accepted";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          accOrg: data?.allAcceptedOrganisationCount,
        }));
      }
    } catch (error) {
      setUsersCount((prev) => ({ ...prev, accOrg: 0 }));
    }
  };

  const getRejectedOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "rejected";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          rejOrg: data?.allRejectedOrganisationCount,
        }));
      }
    } catch (error) {
      setUsersCount((prev) => ({ ...prev, rejOrg: 0 }));
    }
  };

  const getBlockedOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "blocked";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          bloOrg: data?.allBlockedOrganisationCount,
        }));
      }
    } catch (error) {
      setUsersCount((prev) => ({ ...prev, bloOrg: 0 }));
    }
  };

  useEffect(() => {
    if (!isEmpty(adminUser) && keyValues) {
      getAllOrgUsers();
      getAllOrgUsersCount();
      getNewOrgUsersCount();
      getAcceptedOrgUsersCount();
      getRejectedOrgUsersCount();
      getBlockedOrgUsersCount();
    }
  }, [!isEmpty(adminUser)]);

  return {
    users,
    loading,
    usersCount,
    getAllOrgUsers,
    getNewOrgUsers,
    getAcceptedOrgUsers,
    getRejectedOrgUsers,
    getBlockedOrgUsers,
  };
};

export default useApplicantsOrganisation;
