import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../Pages/account/login/Login";
import NotFound from "../../Pages/notFound/NotFound";
import Dashboard from "../../navigation/dashboard/Dashboard";
import ReturnLogin from "../../Pages/returnLogin/ReturnLogin";
import BackendDashboard from "../../Pages/backendDashboard/BackendDashboard";
import SingleApplicantPage from "../../Pages/backendDashboard/singleApplicantPage/SingleApplicantPage.jsx";
import JobPageBack from "../../Pages/backendDashboard/jobpage/JobPageBack.js";
import PaymentPage from "../../Pages/backendDashboard/paymentPage/PaymentPage.jsx";
import useAuth from "../../hooks/useAuth.js";
import IndividualApplicant from "../../Pages/backendDashboard/applicantsPage/individual/IndividualApplicant.js";
import OrganisationApplicant from "../../Pages/backendDashboard/applicantsPage/organisation/OrganisationApplicants.js";
import Settings from "../../Pages/backendDashboard/settings/Settings.js";

const MainRouter = () => {
  useAuth();

  return (
    <Routes>
      <Route index path="/" element={<ReturnLogin />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />

      <Route path="/" element={<Dashboard />}>
        <Route index path="/backend" element={<BackendDashboard />} />
        <Route
          path="/backend/:id/:acc_type"
          element={<SingleApplicantPage />}
        />
        <Route
          path="/applicants/individual"
          element={<IndividualApplicant />}
        />
        <Route
          path="/applicants/organisation"
          element={<OrganisationApplicant />}
        />
        <Route path="/setting" element={<Settings />} />
        <Route path="/jobsback" element={<JobPageBack />} />
        <Route path="/">
          <Route path="/payment" element={<PaymentPage />} />
          {/* <Route path="paymentback/:id" element={<SinglePaymentPage />} /> */}
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRouter;
