import { config } from "../../config";
import axios from "axios";

export const getSkills = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(`${config.testApiBaseUrl}/admin/view-skills`);
  return { data: res.data, status: res.status };
};

export const createSkill = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-skills`,
    field
  );
  return { data: res.data, status: res.status };
};

export const updateSkill = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-skills/${field.id}`,
    field
  );
  return { data: res.data, status: res.status };
};

export const deleteSkillbyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-skills/${id}`
  );
  return { data: res.data, status: res.status };
};