import { config } from "../../config";
import axios from "axios";

export const getStates = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(`${config.testApiBaseUrl}/admin/view-state`);
  return { data: res.data, status: res.status };
};

export const createState = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-state`,
    field
  );
  return { data: res.data, status: res.status };
};

export const updateState = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-state/${field.id}`,
    field
  );
  return { data: res.data, status: res.status };
};

export const getCitiesbyStateId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-state-city/${id}`
  );
  return { data: res.data, status: res.status };
};

export const createCity = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-city`,
    field
  );
  return { data: res.data, status: res.status };
};

export const updateCity = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-city/${field.id}`,
    field
  );
  return { data: res.data, status: res.status };
};

export const deleteCitybyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-city/${id}`
  );
  return { data: res.data, status: res.status };
};

export const deleteStatebyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-state/${id}`
  );
  return { data: res.data, status: res.status };
};
