import React from "react";
import "./BackendDashboard.css";
import ApplicantsPage from "./applicantsPage/ApplicantsPage";
import { Link } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import useQuery from "../../hooks/useQuery";
import useApplicantsOverView from "../../hooks/useApplicantsOverView";

const BackendDashboard = () => {
  const { users, usersCount, loading } = useApplicantsOverView();

  const cardsData = [
    {
      title: "Total Individual",
      num: usersCount.allIn,
      link: "View applicants",
      color: "#F5A656",
      to: "/applicants/individual",
    },
    {
      title: "Total organizations",
      num: usersCount.allOrg,
      link: "View organizations",
      color: "#20A4D3",
      to: "/applicants/organisation",
    },
    {
      title: "Total jobs",
      num: "192",
      link: "View jobs",
      color: "#62D8E0",
      to: "/",
    },
    {
      title: "Total revenue",
      num: "192",
      link: "View revenue",
      color: "#36A695",
      to: "/",
    },
  ];

  const { search, handleChange, newData } = useQuery({
    data: users,
  });

  const {
    currentItems,
    handleNextPage,
    handlePrevPage,
    totalPages,
    currentPage,
  } = usePagination({ data: newData });

  return (
    <ApplicantsPage
      userData={currentItems}
      title={`${usersCount.newAll} Pending approvals`}
      loading={loading}
      currentItems={currentItems}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      totalPages={totalPages}
      currentPage={currentPage}
      search={search}
      handleChange={handleChange}
      NoDataMsg={"No User to Display"}
      children={
        <div className="admin-info-box-2">
          {cardsData.map(({ title, num, link, color, to }, i) => (
            <Link
              to={to}
              key={i}
              className="admin-info-right"
              style={{ backgroundColor: color }}
            >
              <span>{title}</span>
              <h4>{num}</h4>
              <Link to={to} className="link">
                {link}
              </Link>
            </Link>
          ))}
        </div>
      }
    />
  );
};

export default BackendDashboard;
