import { useMemo, useState } from "react";

const useQuery = ({ data }) => {
  const [search, setSearch] = useState("");
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const getDate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const createdDate = new Date(date);
    const monthIndex = createdDate.getMonth();
    const monthName = monthNames[monthIndex];
    const day = createdDate.getDate();
    const year = createdDate.getFullYear();

    return `${monthName} ${day} ${year}`;
  };

  const getStatusLabel = (id) => {
    if (id === "2") {
      return "Verified";
    } else if (id === "3") {
      return "Rejected";
    } else if (id === "4") {
      return "Blocked";
    }

    return "Pending";
  };

  const modifyData = useMemo(() => {
    return data?.map((d) => ({
      id: d.id,
      name: d.name,
      email: d.email,
      account_type: d.account_type,
      status: getStatusLabel(d.status),
      created_at: getDate(d.created_at),
    }));
  }, [data]);

  const filteredData = modifyData?.filter((d) => {
    return (
      search.length > 0 &&
      (d.name.toLowerCase().includes(search?.toLowerCase()) ||
        d.email.toLowerCase().includes(search?.toLowerCase()) ||
        d.status.toLowerCase().includes(search?.toLowerCase()) ||
        d.created_at.toLowerCase().includes(search?.toLowerCase()))
    );
  });

  const newData = search.length > 0 ? filteredData : modifyData;
  return { search, handleChange, newData };
};

export default useQuery;
