import React from "react";
import Location from "./location/Location";
import "./Settings.css"
import Division from "./division/Division";
import OrganisationRole from "./organsation-role/OrgansationRole";
import Skill from "./skill/Skill";

const Settings = () => {
  
  return (
    <div className="settings-container">
      <div className="settings-inner">
        <div className="settings-part">
          <h3>STATES & CITIES</h3>
          <div className="settings-board">
            <Location />
          </div>
        </div>
        <div className="settings-part">
          <h3>DIVISIONS</h3>
          <div className="settings-board">
            <Division />
          </div>
        </div>

        <div className="settings-part">
          <h3>ORGANISATION ROLES</h3>
          <div className="settings-board">
            <OrganisationRole />
          </div>
        </div>

        <div className="settings-part">
          <h3>SKILLS</h3>
          <div className="settings-board">
            <Skill />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
