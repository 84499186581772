import React from "react";
import "./SingleApplicant.css";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";

export const SingleApplicant = ({ name, email, date, status }) => {

  return (
    <div className="content">
      <div className="data-content">
        <div className="part-tr">
          <h5>Full Name</h5>
          <h6>{name}</h6>
        </div>

        <div className="part-tr">
          <h5>Email</h5>
          <h6>{email.length > 15 ? email.substring(0, 15) + "..." : email}</h6>
        </div>
        <div className="part-tr">
          <h5>Date Applied</h5>
          <h6>
            {date}
          </h6>
        </div>
        <div className="part-tr">
          <h5>Status</h5>
          <div className="status-bg">
            <span
              className={`status
            ${
              status === "Pending"
                ? "pend"
                : status === "Verified"
                ? "veri"
                : status === "Rejected"
                ? "rej"
                : status === "Blocked"
                ? "blo"
                : null
            }`}
            >
              {status}
            </span>
          </div>
        </div>
        <div className="dot-bg">
          <BiDotsHorizontalRounded />
        </div>
      </div>
      <div className="dot-bg-mobile">
        <BiDotsHorizontalRounded />
      </div>
    </div>
  );
};
