import { AiOutlineHome } from "react-icons/ai";
import { MdGroup, MdPayment } from "react-icons/md";
import { PiOfficeChairLight } from "react-icons/pi";
import { FaPerson } from "react-icons/fa6";
import { CgOrganisation } from "react-icons/cg";
import { LuLogOut } from "react-icons/lu";
import { CiSettings } from "react-icons/ci";

export const dashboardItems = [
  {
    id: 1,
    name: "Dashboard",
    to: "/backend",
    icon: <AiOutlineHome />,
  },
  {
    id: 2,
    name: "Applicants",
    icon: <MdGroup />,
    child: [
      {
        id: "individual",
        link: "/applicants/individual",
        icon: <FaPerson />,
        label: "Individual",
      },
      {
        id: "organisation",
        link: "/applicants/organisation",
        icon: <CgOrganisation />,
        label: "Organisation",
      },
    ],
  },
  {
    id: 3,
    name: "Jobs",
    to: "/jobsback",
    icon: <PiOfficeChairLight />,
  },
  {
    id: 4,
    name: "Payments",
    to: "/payment",
    icon: <MdPayment />,
  },
  {
    id: 5,
    name: "Settings",
    to: "/setting",
    icon: <CiSettings />
  },
  {
    id: 6,
    name: "Logout",
    to: "/login",
    icon: <LuLogOut />,
  },
];
