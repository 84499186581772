import React, { memo } from 'react'
import get from 'lodash/fp/get'
import { components } from 'react-select'
import '../CustomAutoComplete.css'

const CustomOption = (coProps) => {
  const { Option } = components
  const startAdornment = get(['data', 'startAdornment'], coProps)
  const label = get(['data', 'label'], coProps)

  return (
    <Option {...coProps}>
      <div className='options-body'>
        {startAdornment && (
          <div className='startAdornment'>{startAdornment}</div>
        )}
        <span>{label}</span>
      </div>
    </Option>
  )
}

export default memo(CustomOption)